import { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import { accountFetch, accountUpdate } from "../../classes/database/Accounts";
import { contentFetch, contentUpdate } from "../../classes/database/Content";
import { debounce, dateRange, weeksBetween, getWeekNumber, months, days } from "../../helpers/functions";

import Calendar from "./Calendar";

import SocialTextarea from "../accounts/view/SocialTextarea";
import SocialFeedback from "./SocialFeedback";

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";
import GoogleDriveUploader from "./GoogleDriveUploader";

import { useAuth } from "../../contexts/AuthContext";

registerLocale("nl", nl);

function SocialDisplay({item, index, datePanel, activeSocial, planningId, previewContent, updatePostObject, refetchContent, date, updateDriveId, data,outputPostInformation, current, editable}) {
   
    const [expanded, setExpanded] = useState();


    const outputSimpleDate = (date) => {
		return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
	}
    
    const getDaysBetween = (date1, date2) => {
        var Difference_In_Time = date2.getTime() - date1.getTime();
        return Difference_In_Time / (1000 * 3600 * 24);
    }

    const outputSocialItems = (item, index)=>{
        if(!datePanel){ return null; }
        const channels = current.account.channels;

        const baseDir = `${datePanel.getDate()}-${months[datePanel.getMonth()]}-${datePanel.getFullYear()} key-${index}`;
        if(activeSocial == "Articles"){
            const articleLink = item.image['Articles'] && item.image['Articles'].src && item.image['Articles'].src ? item.image['Articles'].src : ''; 
            return (<div className={`article__view${expanded === baseDir ? ' fullscreen' : ''}`}>
                {articleLink && articleLink.length>0 && <button onClick={() => setExpanded(expanded==baseDir?null:baseDir)} className={expanded != baseDir ? 'expand__file' : 'reduce__file'}></button>}
                {editable && <strong>Google Docs URL</strong>}
                {editable && <input placeholder="Link naar Google Doc" type="text" value={articleLink} onChange={(e) => updatePostObject({"target":{"dataset":{"index":index},"name": "image", "value": e.target.value}})}/>}
                {articleLink && articleLink.length>0 && <iframe src={`${articleLink.replace('/edit', '')}/edit?usp=sharing&rm=minimal&embedded=true`}></iframe>}
            </div>);
        }

        return channels.map((object, key) => {
            if(object.active == false){ return null; }
            if(object.name != activeSocial){ return null; } 
            
            const difference = (Math.round(getDaysBetween(new Date(), datePanel)));
            const fileName = `${datePanel.getDate()}-${months[datePanel.getMonth()]}-${datePanel.getFullYear()} key-${index} v${item.image&& item.image[object.name] && item.image[object.name].version ? item.image[object.name].version + 1 : 1}`;
            const filePath = (!item.image || !item.image[object.name] || !item.image[object.name].src) ? null : 
                (item.image[object.name].src.name.indexOf('.pdf') != item.image[object.name].src.name.length - 4) ? `https://socialrss.zodoende.nl/content/Contentplanningen/${planningId}/${item.image[object.name].src.name}` : 
                `https://socialrss.zodoende.nl/pdfViewer/content/Contentplanningen/${planningId}/${item.image[object.name].src.name}`;
                    
           if(object.name.toLowerCase() == "instagram"){
                return outputInstagram(item, object, key, difference, baseDir, fileName, filePath);
            } else {
                return outputLinkedIn(item, object, key, difference, baseDir, fileName, filePath);
            }
        });
    }

    const outputLinkedIn = (item, object, key, difference, baseDir, fileName, filePath) => {
        return (<div key={key} className="social__item__container">
            <div key={`social_${index}`} className={`zd__social ${object.name.toLowerCase()}`}>
                <div key={0} className="zd__social__head grid two">
                    <div className="zd__column">
                        <span className="zd__social__logo"></span>
                    </div>
                    <div className="zd__social__details">
                    <strong>{current.account.company}</strong>
                        {object.followers ? <span>{object.followers} followers</span> : null}
                        <span>{outputSimpleDate(datePanel)} - {
                            difference > 0 ? 
                                (difference == 1 ? `morgen` : `over ${Math.abs(difference)} dagen`) : 
                            difference == 0 ? `vandaag` : 
                                (difference == -1 ? `gisteren`  : `${Math.abs(difference)} dagen geleden`)
                        }</span>
                    </div>
                </div>
                <div key={1} className="zd__social__description">
                    {editable && <SocialTextarea text={item.description} id={index} update={(i,value) => updatePostObject({"target":{"dataset":{"index":i},"name": "description", "value": value}})}/>}
                    {!editable && <p>{item.description}</p>}
                </div>
                <div key={2} className={`zd__social__image`}>
                    {editable && <GoogleDriveUploader ping={true} pingCallback={refetchContent} directory={[baseDir, 'base']} name={object.name+"-"+fileName} file={filePath} update={(file) => updatePostObject({"target":{"dataset":{"index":index},"name": "image", "value": file}})} driveFolderId={data.driveFolderId} storeDriveFolderId={updateDriveId} title={data.title} />}
                    {!editable && previewContent()}
                </div>
                <div key={3} className="zd__social__actions">
                    <span className="zd__social__logo"></span>
                    <span className="zd__social__like">Like</span>
                    <span className="zd__social__comment">Comment</span>
                    <span className="zd__social__repost">Repost</span>
                    <span className="zd__social__send">Send</span>
                </div>
            </div>
            {outputPostInformation(item, index, baseDir)}
        </div>);
    }

    const outputInstagram = (item, object, key, difference, baseDir, fileName, filePath) => {
        if(item.image == ''){
            item.image = {};
        }
        if(!item.image){
            item.image = {};
        }
        if(!item.image[object.name]){
            item.image[object.name] = {
                name: object.name
            };
        }
        
        let type = null;
        if(!item.image[object.name].type){
            type = 'Post';
        } else {
            type = item.image[object.name].type;
        }
        return (<div key={key} className={`social__item__container`}>
            {editable && <select onChange={(e) => updatePostObject({"target":{"dataset":{"index":index},"name": "image-display", "value": e.target.value}})} value={item.image[object.name].type ? item.image[object.name].type : "post"} name="instagram_post_type">
                <option>Post</option>
                <option>Reel</option>
                <option>Story</option>
            </select>}
            <div key={`social_${index}`} className={`zd__social ${object.name.toLowerCase()}${type ? ` instagram__${type.toLowerCase()}`:''}`}>
                {(type == 'Post' && <div key={0} className="zd__social__head grid two">
                    <div className="zd__column">
                        <span className="zd__social__logo"></span>
                    </div>
                    <div className="zd__social__details">
                    <strong>{current.account.company}</strong>
                        {/* {object.followers ? <span>{object.followers} followers</span> : null} */}
                        <span>{outputSimpleDate(datePanel)} - {
                            difference > 0 ? 
                                (difference == 1 ? `morgen` : `over ${Math.abs(difference)} dagen`) : 
                            difference == 0 ? `vandaag` : 
                                (difference == -1 ? `gisteren`  : `${Math.abs(difference)} dagen geleden`)
                        }</span>
                    </div>
                </div>)}
               
                <div key={2} className={`zd__social__image`}>
                    {(type === 'Story' && <div key={0} className="zd__social__head grid two">
                        <div className="zd__column">
                            <span className="zd__social__logo"></span>
                        </div>
                        <div className="zd__social__details">
                            <strong>{current.account.company}</strong>
                        </div>
                    </div>)}
                    {editable && <GoogleDriveUploader ping={true} pingCallback={refetchContent} directory={[baseDir, 'base']} name={object.name+"-"+fileName} file={filePath} update={(file) => updatePostObject({"target":{"dataset":{"index":index},"name": "image", "value": file}})} driveFolderId={data.driveFolderId} storeDriveFolderId={updateDriveId} title={data.title} />}
                    {!editable && previewContent()}
                    {(type == 'Story' && <span className="instagram__story__date">{outputSimpleDate(datePanel)} - {
                        difference > 0 ? 
                        (difference == 1 ? `morgen` : `over ${Math.abs(difference)} dagen`) : 
                        difference == 0 ? `vandaag` : 
                        (difference == -1 ? `gisteren`  : `${Math.abs(difference)} dagen geleden`)
                    }</span>)}
                    {(type == 'Reel' && <div key={0} className="zd__social__head grid two">
                    <div className="zd__column">
                        <span className="zd__social__logo"></span>
                    </div>
                    <div className="zd__social__details">
                        <strong>{current.account.company}</strong>
                        {/* {object.followers ? <span>{object.followers} followers</span> : null} */}
                    </div>
                    <div key={1} className="zd__social__description">
                        {(type == 'Reel' && <span>{outputSimpleDate(datePanel)} - {
                            difference > 0 ? 
                                (difference == 1 ? `morgen` : `over ${Math.abs(difference)} dagen`) : 
                            difference == 0 ? `vandaag` : 
                                (difference == -1 ? `gisteren`  : `${Math.abs(difference)} dagen geleden`)
                        }</span>)}
                        {editable && <SocialTextarea text={item.description} id={index} update={(i,value) => updatePostObject({"target":{"dataset":{"index":i},"name": "description", "value": value}})}/>}
                        {!editable && <p>{item.description}</p>}
                    </div>
                    {(type == 'Reel' && <div key={3} className="zd__social__actions">
                        <span className="zd__social__like"></span>
                        <span className="zd__social__comment"></span>
                        <span className="zd__social__send"></span>
                    </div>)}
                </div>)}
                </div>
                {(type == 'Post' && <div key={3} className="zd__social__actions">
                    <span className="zd__social__like"></span>
                    <span className="zd__social__comment"></span>
                    <span className="zd__social__send"></span>
                    <span className="zd__social__bookmark"></span>
                </div>)}
                {(type == 'Post' && <div key={1} className="zd__social__description">
                    {editable && <SocialTextarea text={item.description} id={index} update={(i,value) => updatePostObject({"target":{"dataset":{"index":i},"name": "description", "value": value}})}/>}
                    {!editable && <p>{item.description}</p>}
                </div>)}
            </div>
            {outputPostInformation(item, index, baseDir)}
        </div>);
    }

    return item ? outputSocialItems(item, index) : null;
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialDisplay);
