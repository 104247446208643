import { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import { accountFetch, accountUpdate } from "../../classes/database/Accounts";
import { contentFetch, contentUpdate } from "../../classes/database/Content";
import { debounce, dateRange, weeksBetween, getWeekNumber, months, days } from "../../helpers/functions";

import Calendar from "./Calendar";

import SocialTextarea from "../accounts/view/SocialTextarea";
import SocialFeedback from "./SocialFeedback";
import SocialDisplay from "./SocialDisplay";

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";
import GoogleDriveUploader from "./GoogleDriveUploader";

import { useAuth } from "../../contexts/AuthContext";

registerLocale("nl", nl);

function ViewContentPlanning(props) {
    const { id, planningId } = useParams();
    
    const [data, setData] = useState();
    const [activeDays, setActiveDays] = useState();
    const [datePanel, setDatePanel] = useState(false);
    const [contextMenu, setContextMenu] = useState(null);
    const [removePost, setRemovePost] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [upload, setUpload] = useState(null);
    const [editRubrics, setEditRubrics] = useState(false);
    const [addRubric, setAddRubric] = useState('');
    const [currentMonth, setCurrentMonth] = useState(null);
    const [addDate, setAddDate] = useState(null);
    const [activeSocial, setActiveSocial] = useState(null);

    const [showDeadline, setShowDeadlines] = useState(false);
    const [editDeadline, setEditDeadline] = useState(false);
    const [showImporter, setShowImporter] = useState(false);
    
    const { isAdmin } = useAuth();

    const statuses = {
        "input" : [
            'Input verzamelen',
            'Feedback op input (1)',
            'Feedback op input verwerken',
            'Feedback op input (2)',
            'Feedback op input verwerken (2)',
            'Content maken',
        ],
        "content" : [
            'Input verzamelen',
            'Content maken',
            'Feedback op content (1)',
            'Feedback op content verwerken',
            'Feedback op content (2)',
            'Feedback op content verwerken (2)',
            'Content ingepland'
        ]
    };
    
    const accounts = props.collections.accounts
    ? props.collections.accounts
    : {};
    const setAccounts = (items) => {
        props.setAccounts(items);
    };
    
    useEffect(async () => {
        props.setCurrentPosts(null);
		props.setCurrentLeads(null);
        
        
		const user = accounts[id] ? accounts[id] : await accountFetch(id);
		props.setCurrentAccount(user);
		if (!accounts[id]) {
            const _accounts = { ...accounts };
			_accounts[id] = user;
			props.setAccounts(_accounts);
		}
		return () => {
            props.setCurrentAccount(null);
			props.setCurrentLeads([]);
			props.setCurrentPosts([]);
		};
	}, []);
    
    useEffect(async () => {
        const plans = await contentFetch(id, planningId);
        if(!plans){ return; }
        setData(plans);
    }, []);

    useEffect(async () => {
        if(!props.current) { return; }
        if(!props.current.account) { return; }
        if(!props.current.account.channels) { return; }

        let social = null;
        props.current.account.channels.map((item, i) => {
            if(social != null){ return; }
            social = item.name
        });
        setActiveSocial(social);
    }, [props.current.account]);
    
    useEffect(() => {
        if(!data){ return; }
        if(currentMonth){ return; }
        setCurrentMonth(new Date(data.startPeriod.seconds * 1000));
        setActiveDays(dateRange(
            new Date(data.startPeriod.seconds * 1000),
            new Date(data.endPeriod.seconds * 1000),
            true
            ));
        }, [data]);
        
        useEffect(() => {
            if(!data){ return; }
            if(data.items){ return; }
            
            const items = createDefaultItems();
            const numberOfWeeks = weeksBetween(
                new Date(data.startPeriod.seconds * 1000),
                new Date(data.endPeriod.seconds * 1000)
                );
                
                const numberOfPosts = numberOfWeeks * data.frequency;
                const plannedPosts = items.length;
                const newData = {...data, items : items, 'numberOfPosts' : numberOfPosts, 'plannedPosts' : plannedPosts};
                contentUpdate(planningId, newData, id);
                setData(newData);
            }, [activeDays]);
            
    const updateDriveId = async (id, key) => {
        if(!id){ return; }
        
        let idStore = null;
        if(!key){
            idStore = id;
        } else if (typeof data.driveFolderId === 'string'){
            idStore = {
                "base" : data.driveFolderId
            };
            idStore[key] = id;
        } else {
            idStore = { ...data.driveFolderId }
            idStore[key] = id;
        }
        
        const newData = {
            ...data,
            driveFolderId: idStore
        }
        await contentUpdate(planningId, newData, id);
        setData(newData);
    };

    const debouncedHandler = useCallback(
		debounce(contentUpdate, 500),
		[contentUpdate]
	  );

      const outputDate = (date) => {
		if(!date || !date.seconds){ return; }
		const newDate = new Date(date.seconds * 1000);
		return newDate.getDate() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getFullYear();
	}
    const outputSimpleDate = (date) => {
		return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
	}
	const displayDate = (item, name, index) => {
        const date = new Date(item[name].data);
		if(!date){ return; }
        const dateString = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
        if(editDeadline){
            const startPeriod = new Date(data.startPeriod.seconds * 1000);
            const endPeriod = new Date(data.endPeriod.seconds * 1000);
            return <DatePicker minDate={startPeriod} maxDate={endPeriod} placeholderText="Selecteer datum.." dateFormat="dd-MM-yyyy" locale="nl" selected={date} onChange={(date, e) => {updateDeadlines(date, name, index)}} />
        }
		return dateString;
	}
    const updateDeadlines = async (date, name, index) => {
        const dateString =  date.getFullYear() + "-" + 
                            ((date.getMonth() + 1) < 10 ? "0"+(date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + 
                            ((date.getDate() < 10 ? "0"+date.getDate() : date.getDate()));
        
        const newPhases = [...data.phases];
        newPhases[index][name].data = dateString;
        const response = await updateZohoRecord(newPhases);

        if(response){
            updateObject({
                target: {
                    name: "phases",
                    value: newPhases
                }
            });
        } else {
            alert('Er iets iets fout gegaan');
        }
    };
	const outputFrequency = (number, interval = null) => {
		if(number == 1){
			return 'Eens per week'
		}
		if(number == 1.5){
			if(interval == 'even'){
                return 'Eens per week + uitgelichte post elke even week';
            } else if (interval == 'odd'){
                return 'Eens per week + uitgelichte post elke oneven week';
            }
		}
		if(number == 2){
			return 'Twee keer per week'
		}
	}

    const outputWeekDay = (day) => {
        return days[day];
    }

    const storePlanning = () => {

    }

    const outputPlacement = (data) => {
        const items = [];
        Object.keys(data).map((key, index) => {
            if(data[key] == 'free'){
                items.push(<p key={index} className={key}>Post één is vrij in te delen</p>);
            } else if([0,1,2,3,4,5,6].indexOf(data[key])){
                items.push(<p key={index} className={key}>Post wordt geplaatst op {outputWeekDay(data[key])}</p>);
            }
        });
        return items;
    }

    const updateZohoRecord = async (newData) => {
		const rawResponse = await fetch(`https://socialrss.zodoende.nl/update/Contentplanningen/${planningId}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
			},
			body: JSON.stringify({"Deadlines" : newData})
		});
		const response = await rawResponse.json();
		
		if(response.code == 200){
			return response.data[0];
		}
		return null;
	}

    const createDefaultItems = () => {
        const filtered = {};
        let size = 0;
        activeDays.filter((item, key) => {
            if(item.getDay() == data.placement.first){
                filtered[item.getFullYear()+""+item.getMonth()+""+item.getDate()] = {
                    date : new Date(item),
                    posts:[],
                    prePlanned: true
                };
                size++;
            } else if(item.getDay() == data.placement.second){
                let include = false;
                if(data.onceEveryOtherWeek == 'even'){
                    include = getWeekNumber(item) % 2 == 0 ? true : false;
                } else if(data.onceEveryOtherWeek == 'odd'){
                    include = getWeekNumber(item) % 2 == 1 ? true : false;
                }

                if(data.frequency == 2 || include){
                    filtered[item.getFullYear()+""+item.getMonth()+""+item.getDate()] = {
                        date : new Date(item),
                        posts:[],
                        prePlanned: true
                    };
                    size++;
                }
            }
        });
        filtered.length = size;
        return filtered;
    }

    const openDatePanel = (date) => {
        setDatePanel(date);
    }

    const buildCalendarItems = () => {
        return data.items;
    }

    const displaySidebar = () => {
        return [
            outputDatePanel(),
            outputContentPanelDetail()
        ];
    }

    const addEmptyObject = (key) => {
        const newData = { ...data };
        newData.items[key] = {
            date : new Date(datePanel),
            isEmpty:true,
            posts:[
                // {
                //     title:"",
                //     description:"",
                //     image:{}
                // }
            ]
        };
        setData(newData);
    }

    const updateObject = async (e) => {
        const newData = {...data};

        if(e.target.name == 'add-rubric'){
            newData.rubrics.push(e.target.value);
            setAddRubric('');
        } if(e.target.name == "change-date") {
            const post = newData.items[e.target.dataset.date].posts[e.target.dataset.index];
            const remainingPosts = newData.items[e.target.dataset.date].posts.filter((item, index) => {
                return index != e.target.dataset.index ? true : false;
            });
            
            if(remainingPosts.length == 0){
                const temp = {};
                Object.keys(newData.items).map((item) => {
                    if(item != e.target.dataset.date){
                        temp[item] = newData.items[item];
                    }
                });
                newData.items = temp;
                setDatePanel(null);
            }
            
            const datasetKey = e.target.value.getFullYear()+""+e.target.value.getMonth()+""+e.target.value.getDate();
            if(typeof newData.items[datasetKey] == 'undefined'){
                newData.items[datasetKey] = {
                    posts:[],
                    date: null
                };
            }
            newData.items[datasetKey].posts.push(post);
            newData.items[datasetKey].date = new Date(e.target.value);
        } else {
            newData[e.target.name] = e.target.value;
        }
        
        debouncedHandler(planningId, newData, id);
        setData(newData);
    }

    const updatePostObject = async (e) => {
        let key = null;
        if(datePanel){
            key = datePanel.getFullYear()+""+datePanel.getMonth()+""+datePanel.getDate();
        } else if(e.target.dataset.date){
            key = e.target.dataset.date;
        }
        const newData = {...data};

        delete newData.items[key].isEmpty;
        
        if(e.target.name == 'image-display'){
            if(!activeSocial){ return; }
            newData.items[key].posts[e.target.dataset.index]['image'][activeSocial].type = e.target.value;
        }

        if(e.target.name == "image"){
            if(!activeSocial){ return; }

            if (
                typeof newData.items[key].posts[e.target.dataset.index][e.target.name] !== 'object' &&
                !Array.isArray(newData.items[key].posts[e.target.dataset.index][e.target.name]) &&
                newData.items[key].posts[e.target.dataset.index][e.target.name] !== null
            ) {
                newData.items[key].posts[e.target.dataset.index][e.target.name] = {};
            }

            if (
                typeof newData.items[key].posts[e.target.dataset.index][e.target.name][activeSocial] !== 'object' &&
                !Array.isArray(newData.items[key].posts[e.target.dataset.index][e.target.name][activeSocial]) &&
                newData.items[key].posts[e.target.dataset.index][e.target.name][activeSocial] !== null
            ) {
                newData.items[key].posts[e.target.dataset.index][e.target.name][activeSocial] = {
                    src: e.target.value,
                    version: 0
                };
            }

            newData.items[key].posts[e.target.dataset.index][e.target.name][activeSocial].src = e.target.value;
            newData.items[key].posts[e.target.dataset.index][e.target.name][activeSocial].version = newData.items[key].posts[e.target.dataset.index][e.target.name][activeSocial].version + 1;
        }else if(e.target.name == "file-feedback"){
            newData.items[key].posts[e.target.dataset.index]['feedback'].push({
                "message": `Bestand: ${e.target.value.name}`,
                "driveLink":  `https://drive.google.com/file/d/${e.target.value.id}/view`,
                "reactions" : [],
                "userId" : props.current.account.id,
                "userName" : props.current.account.name
            });
        } else if(e.target.name == "file-input"){
            newData.items[key].posts[e.target.dataset.index]['input'].push({
                "message": `Bestand: ${e.target.value.name}`,
                "driveLink":  `https://drive.google.com/file/d/${e.target.value.id}/view`,
                "reactions" : [],
                "userId" : props.current.account.id,
                "userName" : props.current.account.name
            });
        } else {
            newData.items[key].posts[e.target.dataset.index][e.target.name] = e.target.value;
        }

        debouncedHandler(planningId, newData, id);
        setData(newData);
    }

    const doRemovePost = (index) => {
        setContextMenu(null);
        setRemovePost(null);

        const key = datePanel.getFullYear()+""+datePanel.getMonth()+""+datePanel.getDate();
        const newData = {...data};
        newData.items[key].posts = newData.items[key].posts.filter((item, i) => {
            return index != i ? true : false;
        });

        if(newData.items[key].posts.length == 0){
            const temp = {};
            Object.keys(newData.items).map((item) => {
                if(item != key){
                    temp[item] = newData.items[item];
                }
            });
            newData.items = temp;
            setDatePanel(null);
        }
        
        debouncedHandler(planningId, newData, id);
        setData(newData);

    }

    const maybeOutputContextMenu = (index) => {
        if(contextMenu != index){ return }
        let html = (
            <div className="default__inner">
                <button key={0} onClick={()=>setRemovePost(index)} className="remove__post">Verwijder post</button>
                <button key={1} onClick={()=>setContextMenu(null)} className="close__menu">Sluiten</button>
            </div>
        );
        
        if(removePost != null){
            html = (<div className="remove__inner">
                <p>Weet je zeker dat je deze post wilt verwijderen?</p>
                <button onClick={()=>doRemovePost(index)} className="remove__post">Verwijderen</button>
                <button onClick={()=>{
                    setContextMenu(null);
                    setRemovePost(null);
                }} className="close__menu">Annuleren</button>
            </div>);
        }
        
        return (<div className="context__menu__pop__up">{html}</div>);
    }

    const refetchContent = async () => {
        const response = await fetch("https://socialrss.zodoende.nl/feed/Contentplanningen/"+planningId);
        return response;
    }

    const outputModeSwitcher = (item, index) => {
        return (
            <div className="post__mode__switcher">
                <button className={item.postMode != "content" ? "active" : ""} onClick={(e)=>updatePostObject({"target":{"dataset":{"index":index},"name": "postMode", "value": "input"}})}>Input</button>
                <button className={item.postMode == "content" ? "active" : ""} onClick={(e)=>updatePostObject({"target":{"dataset":{"index":index},"name": "postMode", "value": "content"}})}>Content</button>
            </div>
        );
    }

    const outputPostInformation = (item, index, baseDir, date = datePanel) => {
        if(item.postMode == "content"){
            return (
                <div key={`feedback_${index}`} className="zd__feedback__panel">
                    {outputModeSwitcher(item, index)}
                    <SocialFeedback placeholder="feedback" data={item.feedback} index={index} update={(value, key) => updatePostObject({"target":{"dataset":{"index":key, "date" : date.getFullYear()+""+date.getMonth()+""+date.getDate()},"name": "feedback", "value": value}})}>
                        <button className="zd__attachment" onClick={()=> setUpload(index)}></button>
                        {(upload === index && <GoogleDriveUploader cancelable={() => setUpload(false)} directory={[baseDir, "feedback"]} name={null} update={(file) => { setUpload(false); updatePostObject({"target":{"dataset":{"index":index},"name": "file-feedback", "value": file}}); }} driveFolderId={data.driveFolderId} storeDriveFolderId={updateDriveId} title={data.title}>
                        </GoogleDriveUploader>)}
                    </SocialFeedback>
                </div>
            );
        } else {
            return (
                <div key={`input_${index}`} className="zd__feedback__panel">
                    {outputModeSwitcher(item, index)}
                    <SocialFeedback placeholder="input" data={item.input} index={index} update={(value, key) => updatePostObject({"target":{"dataset":{"index":key, "date" : date.getFullYear()+""+date.getMonth()+""+date.getDate()},"name": "input", "value": value}})}>
                        <button className="zd__attachment" onClick={()=> setUpload(index)}></button>
                        {(upload === index && <GoogleDriveUploader cancelable={() => setUpload(false)} directory={[baseDir, "input"]} name={null} update={(file) => { setUpload(false); updatePostObject({"target":{"dataset":{"index":index},"name": "file-input", "value": file}}); }} driveFolderId={data.driveFolderId} storeDriveFolderId={updateDriveId} title={data.title}>
                        </GoogleDriveUploader>)}
                    </SocialFeedback>
                </div>
            );
        }
    }
    
    const getDaysBetween = (date1, date2) => {
        var Difference_In_Time = date2.getTime() - date1.getTime();
        return Difference_In_Time / (1000 * 3600 * 24);
    }

    const outputSocialItems = (item, index)=>{
        if(!datePanel){ return null; }

        const channels = props.current.account.channels;

        const baseDir = `${datePanel.getDate()}-${months[datePanel.getMonth()]}-${datePanel.getFullYear()} key-${index}`;
            
        return <SocialDisplay 
            key={index}
            item={item}
            index={index}
            datePanel={datePanel} 
            activeSocial={activeSocial}
            planningId={planningId}
            updatePostObject={updatePostObject}
            outputPostInformation={outputPostInformation}
            updateDriveId={updateDriveId}
            refetchContent={refetchContent}
            data={data}
            editable={true}
            previewContent={function(){ return null; }}
        />

        return channels.map((object, key) => {
            if(object.active == false){ return null; }
            if(object.name != activeSocial){ return null; } 
            
            const difference = (Math.round(getDaysBetween(new Date(), datePanel)));
            const fileName = `${datePanel.getDate()}-${months[datePanel.getMonth()]}-${datePanel.getFullYear()} key-${index} v${item.image&& item.image[object.name] && item.image[object.name].version ? item.image[object.name].version + 1 : 1}`;
            const filePath = (!item.image || !item.image[object.name]) ? null : 
                (item.image[object.name].src.name.indexOf('.pdf') != item.image[object.name].src.name.length - 4) ? `https://socialrss.zodoende.nl/content/Contentplanningen/${planningId}/${item.image[object.name].src.name}` : 
                `https://socialrss.zodoende.nl/pdfViewer/content/Contentplanningen/${planningId}/${item.image[object.name].src.name}`;

            
                    
            return (
                <div key={key} className="social__item__container">
                    <div key={`social_${index}`} className={`zd__social ${object.name.toLowerCase()}`}>
                        <div key={0} className="zd__social__head grid two">
                            <div className="zd__column">
                                <span className="zd__social__logo"></span>
                            </div>
                            <div className="zd__social__details">
                            <strong>{props.current.account.company}</strong>
                                {object.followers ? <span>{object.followers} followers</span> : null}
                                <span>{outputSimpleDate(datePanel)} - {
                                    difference > 0 ? 
                                        (difference == 1 ? `morgen` : `over ${Math.abs(difference)} dagen`) : 
                                    difference == 0 ? `vandaag` : 
                                        (difference == -1 ? `gisteren`  : `${Math.abs(difference)} dagen geleden`)
                                }</span>
                            </div>
                        </div>
                        <div key={1} className="zd__social__description">
                            <SocialTextarea text={item.description} id={index} update={(i,value) => updatePostObject({"target":{"dataset":{"index":i},"name": "description", "value": value}})}/>
                        </div>
                        <div key={2} className={`zd__social__image`}>
                            <GoogleDriveUploader ping={true} pingCallback={refetchContent} directory={[baseDir, 'base']} name={object.name+"-"+fileName} file={filePath} update={(file) => updatePostObject({"target":{"dataset":{"index":index},"name": "image", "value": file}})} driveFolderId={data.driveFolderId} storeDriveFolderId={updateDriveId} title={data.title} />
                        </div>
                        <div key={3} className="zd__social__actions">
                            <span className="zd__social__logo"></span>
                            <span className="zd__social__like">Like</span>
                            <span className="zd__social__comment">Comment</span>
                            <span className="zd__social__repost">Repost</span>
                            <span className="zd__social__send">Send</span>
                        </div>
                    </div>
                    {outputPostInformation(item, index, baseDir)}
                </div>
            );
        });
    }

    const outputStatusDropdown = (item, index, dateKey = null) => {
        let key = "input";
        if(item.status) {
            if(item.status != "Input verzamelen" && statuses.content.indexOf(item.status)){
                key = "content";
            }
        }
        
        const options = statuses[key];
        if(options.indexOf(item.status) < 0){
            item.status = "Input verzamelen";
        }
        return (
            <select key={5} onChange={updatePostObject} data-date={dateKey} data-index={index} name="status" value={(item.status)?item.status:''}>
                <option value="">Selecteer een status</option>
                {options.map((_item, _index) => {
                    return <option key={_index} value={_item}>{_item}</option>
                })}
            </select>
        );
    }

    const outputPost = (item, index) => {                
        let key = "input";
        if(item.status) {
            if(item.status != "Input verzamelen" && statuses.content.indexOf(item.status) >= 0){
                key = "content";
            }
        }
        
        const options = statuses[key];
        if(options.indexOf(item.status) < 0){
            item.status = "Input verzamelen";
        }

        const channels = props.current.account.channels;
        return (<div key={index} className="post__item">
            <button key={0} className="context__menu" onClick={()=>setContextMenu(contextMenu === null || index != contextMenu ? index : null)}></button>
            {maybeOutputContextMenu(index)}
            <strong className="input__label">Titel</strong>
            <input  key={2} onChange={updatePostObject} data-index={index} name="title" placeholder="Voer een post titel in.." value={item.title}/>  
            <strong className="input__label">Benodigdheden</strong>
            <textarea  key={7} onChange={updatePostObject} data-index={index} name="needs" placeholder="Voer benodigheden in.." value={item.needs}/>  
            <strong className="input__label">Omschrijving</strong>
            <textarea  key={3} onChange={updatePostObject} data-index={index} name="notes" placeholder="Voer een notities in.." value={item.notes}/>  
            <strong className="input__label">Rubrieken</strong>
            <select  key={4} onChange={updatePostObject} name="rubric" data-index={index} value={(item.rubric)?item.rubric:''}>
                <option value="">Selecteer een rubirek</option>
                {data.rubrics.map((_item, _index) => {
                    return <option key={_index} value={_index}>{_item}</option>
                })}
            </select>
            <strong className="input__label">Status</strong>
            <select key={5} onChange={updatePostObject} data-index={index} name="status" value={(item.status)?item.status:''}>
                <option value="">Selecteer een status</option>
                {options.map((_item, _index) => {
                    return <option key={_index} value={_item}>{_item}</option>
                })}
            </select>
            <div  key={6} className="post__inner__container">
                <div className="social__switcher">
                    <button key={-1} onClick={()=>setActiveSocial("Articles")} className={`social__switch${activeSocial == 'Articles' ? ' active' : ''}`} >Artikel</button>
                    { channels.map((object, key) => {
                        return <button key={key} onClick={()=>setActiveSocial(object.name)} className={`social__switch${object.active ? '' : ' inactive'}${activeSocial == object.name ? ' active' : ''}`} disabled={object.active ? false : true}>{object.name}</button>
                    })}
                </div>
                {outputSocialItems(item, index)}
            </div>
        </div>);
    }

    const addPost = (e) => {
        const key = datePanel.getFullYear()+""+datePanel.getMonth()+""+datePanel.getDate();
        const newData = { ...data };
        const dateItem = {
            ...newData.items[key]
        };
        dateItem.posts.push({
            title:"",
            notes:"",
            description:"",
            image:{},
            feedback:[],
            input: []
        });
        newData.items[key] = dateItem;
        setData(newData);
    }

    const outputEmptyPanel = () => {
        const phases = (data.phases || []).sort((a,b)=>{
            return new Date(a.Start_periode.data) < new Date(b.Start_periode.data) ? -1 : 1;
        });
        const channels = ((props.current && props.current.account && props.current.account.channels) || []);        

        return (
            <aside key={0} className="content__info__box">
                <div key={7} className="grid two"><strong>Preview</strong><Link to={`/account/${id}/content-marketing/${planningId}/preview`}>Naar Social Feed</Link></div>
                <div key={0} className="grid two"><strong>Periode</strong><div>{outputDate(data.startPeriod)} t/m {outputDate(data.endPeriod)}</div></div>
                <div key={1} className="grid two"><strong>Contentproductie eens per</strong><div>{data.productionTime} maanden</div></div>
                <div key={2} className="grid two"><strong>Frequentie</strong><div>{outputFrequency(data.frequency, data.onceEveryOtherWeek)}</div></div>
                {/* <div key={3} className="grid two"><strong>Plaatsen</strong><div>{outputPlacement(data.placement)}</div></div> */}
                
                <div key={4} className="grid two"><strong>Doelgroep</strong><div>{data.targetgroup}</div></div>
                <div key={5} className="grid two">
                    <div>
                        <strong>Rubrieken</strong>
                        <button className="rubric__edit__button" onClick={()=>setEditRubrics(editRubrics ? false : true)}>{editRubrics ? 'annuleren' : 'edit'}</button>
                    </div>
                    <div>
                        {data.rubrics.map((item,index) => {
                            return <span key={index} className="tag">{item}</span>
                        })}
                        {(editRubrics && <div>
                            <input type="text" name="add-rubric" value={addRubric} onChange={(e)=>setAddRubric(e.target.value)} />
                            <button onClick={(e)=>updateObject({"target":{"name": "add-rubric", "value": addRubric}})} className="add__rubric"></button>
                        </div>)}
                    </div>
                </div>
                <div key={6} className="grid two"><strong>XML bestand</strong><a href={`https://socialrss.zodoende.nl/feed/Contentplanningen/${planningId}`} target="_blank" rel="noopener noreferrer"> Open bestand</a></div>                
                <div key={9} className="exporter__container">
                    <div className="grid two">
                        <strong>Bulk Exporter</strong>
                        <button onClick={() => { setShowImporter(showImporter ? false : true); }}>Bekijk downloads</button>
                    </div>
                    {showImporter && (
                        <div className="deadline__table">
                            <div  className="grid two" key={-1}>
                                <span key={1}>
                                    <strong>Social</strong>
                                </span>
                                <span key={2}>
                                    <strong>Link</strong>
                                </span>
                            </div>
                            <div  className="grid two" key={-2}>
                                <span key={1}>
                                    Alle
                                </span>
                                <span key={2}>
                                    <a href={`https://socialrss.zodoende.nl/export/Contentplanningen/${planningId}`} target="_blank" rel="noopener noreferrer"> Download bestand</a>
                                </span>
                            </div>
                            {channels.map((item, i)=>{
                                if(!item.active) { return null; }
                                return <div  className="grid two" key={i}>
                                    <span key={1}>
                                        {item.name}
                                    </span>
                                    <span key={2}>
                                        <a href={`https://socialrss.zodoende.nl/export/${item.name.toLowerCase()}/Contentplanningen/${planningId}`} target="_blank" rel="noopener noreferrer"> Download bestand</a>
                                    </span>
                                </div>;
                            })}
                        </div>
                    )}
                </div>
                
                <div key={8} className="deadline__container">
                    <div className="grid two">
                        <strong>Deadlines</strong>
                        <button onClick={() => { setShowDeadlines(showDeadline ? false : true); }}>Bekijk deadlines</button>
                    </div>
                    {showDeadline && (
                        <div className="deadline__table">
                            <div  className="grid six" key={-1}>
                                <span key={1}>
                                    <strong>Type</strong>
                                </span>
                                <span key={2}>
                                    <strong>Begin</strong>
                                </span>
                                <span key={3}>
                                    <strong>Feedback 1</strong>
                                </span>
                                <span key={4}>
                                    <strong>Feedback 2</strong>
                                </span>
                                <span key={5}>
                                    <strong>Einde</strong>
                                </span>
                                <span key={6}>
                                    <strong>Deadline</strong>
                                </span>
                            </div>
                            {phases.map((item, i)=>{
                            return <div  className="grid six" key={i}>
                                <span key={1}>
                                    {item.Type.data}
                                </span>
                                <span key={2}>
                                    {displayDate(item, "Start_periode", i)}
                                </span>
                                <span key={3}>
                                    {displayDate(item, "Feedback_datum_1", i)}
                                </span>
                                <span key={4}>
                                    {displayDate(item, "Feedback_datum_2", i)}
                                </span>
                                <span key={5}>
                                    {displayDate(item, "Eind_periode", i)}
                                </span>
                                <span key={6}>
                                    {displayDate(item, "Deadline", i)}
                                </span>  
                            </div>;
                            })}
                            <button type="button" onClick={()=>{ setEditDeadline(editDeadline ? false : true); }} className="button">{editDeadline ? "Stop met bewerken" : "Bewerk deadlines"}</button>                             
                        </div>
                    )}
                </div>
            </aside>
        );
    }

    const outputDatePanel = () => {
        if(!datePanel){ return  outputEmptyPanel(); }
        if(!data){ return null; }
        const key = datePanel.getFullYear()+""+datePanel.getMonth()+""+datePanel.getDate();
        
        if(!data.items[key]){
            addEmptyObject(key);
            return null;
        }
        
        return (
            <aside key={0} className="content__info__box">
                <button key={0} className="close__panel" onClick={()=>setDatePanel(false)}></button>
                <h2 key={1}>{datePanel.getDate()} {months[datePanel.getMonth()]} {datePanel.getFullYear()}</h2>
                <div key={2} className="post__container zd__planning__container">
                    {data.items[key].posts.map(outputPost)}
                    <button onClick={addPost} className="button add__post">Post toevoegen</button>
                </div>
            </aside>
        );
    }

    const outputContentPanelDetail = () => {
        const plannedItems = buildCalendarItems();
        let counter = 0;
        let prePlanned = 0;
        Object.keys(plannedItems).map((item)=>{
            if(plannedItems[item].prePlanned === true){
                counter++;
                prePlanned++;
            } else if(plannedItems[item].posts && plannedItems[item].posts.length > 0){
                counter++;
            }
        });

        const startPeriod = new Date(data.startPeriod.seconds * 1000);
        const endPeriod = new Date(data.endPeriod.seconds * 1000);

        return (
            <aside key={1} className="content__info__box calendar">
                <div key={0} className="grid two"><strong>Ingepland posts</strong><div>{counter}/{(data.numberOfPosts < prePlanned ? prePlanned : data.numberOfPosts)}</div></div>
                <Calendar key={1} onMonthChange={setCurrentMonth} onSelect={openDatePanel} items={buildCalendarItems()} displayAll={true} start={new Date(data.startPeriod.seconds * 1000)} end={new Date(data.endPeriod.seconds * 1000)}></Calendar>
                <div className="add__container">
                    <strong>Post toevoegen</strong>
                    <div>
                        <DatePicker minDate={startPeriod} maxDate={endPeriod} placeholderText="Selecteer datum.." dateFormat="dd-MM-yyyy" locale="nl" selected={addDate} onChange={(date) => setAddDate(date)} />
                        <button onClick={addPostToDate}>toevoegen</button>
                    </div>
                </div>
            </aside>
        );
    }

    const displayListView = () => {
        if(datePanel){ return null; }
        
        const frequency = data.frequency;
        const isDoublePost = data.placement.first == data.placement.first && data.placement.first != 'free' ? true : false;
    
        const startPeriod = new Date(data.startPeriod.seconds * 1000);
        const endPeriod = new Date(data.endPeriod.seconds * 1000);

        const keys = Object.keys(data.items).sort((a,b)=>{
            if(a == 'length' || b == 'length'){return false;}
            const dateA = (data.items[a].date.seconds ? new Date(data.items[a].date.seconds * 1000) : data.items[a].date);
            const dateB = (data.items[b].date.seconds ? new Date(data.items[b].date.seconds * 1000) : data.items[b].date);
            return dateA < dateB ? -1 : 1;
        });

        const list = keys.map((dateKey, key) => {
            if(currentMonth == null){ return null; }
            const dateObject = data.items[dateKey];
            
            if(typeof dateObject != "object"){ return null; }
            if(!dateObject.date.seconds){
                dateObject.date = { seconds: dateObject.date.getTime() / 1000 };
            }

            const publishDate = new Date(dateObject.date.seconds * 1000);
            if(publishDate.getMonth()+""+publishDate.getFullYear() != currentMonth.getMonth()+""+currentMonth.getFullYear()){
                return null;
            }
            const datasetKey = publishDate.getFullYear()+""+publishDate.getMonth()+""+publishDate.getDate();

            if(dateObject.posts.length == 0){
                dateObject.posts.push({
                    description: "",
                    title: "",
                    postMode: "input",
                    status: "input verzamelen"
                });
            }
            
            return dateObject.posts.map((post, index) => {
                let key = "input";
                if(post.status) {
                    if(post.status != "Input verzamelen" && statuses.content.indexOf(post.status) >= 0){
                        key = "content";
                    }
                }
                
                const options = statuses[key];
                if(options.indexOf(post.status) < 0){
                    post.status = "Input verzamelen";
                }

                const baseDir = `${publishDate.getDate()}-${months[publishDate.getMonth()]}-${publishDate.getFullYear()} key-${index}`;
                post.postMode = "input";
                return (<div key={index} className="list__table__item">
                    <h2 key={0} className="date__label">{outputDate(dateObject.date)}</h2>
                    <div key={5}>
                        <strong>Benodigdheden</strong>
                        <SocialTextarea placeholder="Voer de benodigdheden in.." text={post.needs ? post.needs : ''} id={index} update={(i,value) => updatePostObject({"target":{"dataset":{"index":i, "date":datasetKey},"name": "needs", "value": value}})}/>
                    </div>
                    <div key={4}>
                        <strong>Omschrijving</strong>
                        <SocialTextarea text={post.description} id={index} update={(i,value) => updatePostObject({"target":{"dataset":{"index":i, "date":datasetKey},"name": "description", "value": value}})}/>
                    </div>
                    <div key={3} className="list__name">
                        <strong>Naam</strong>
                        <input key={2} onChange={updatePostObject} data-date={datasetKey} data-index={index} name="title" placeholder="Voer een post titel in.." value={post.title}/>  
                    </div>
                    <div key={1}>
                        <strong>Status</strong>
                        <select key={5} onChange={updatePostObject} data-date={datasetKey} data-index={index} name="status" value={(post.status)?post.status:''}>
                            <option value="">Selecteer een status</option>
                            {options.map((_item, _index) => {
                                return <option key={_index} value={_item}>{_item}</option>
                            })}
                        </select>
                    </div>
                    <div key={2}>
                        <strong>Publicatie datum</strong>
                        <div className="grid two">
                            <div>
                                <DatePicker minDate={startPeriod} maxDate={endPeriod} placeholderText="Selecteer datum.." dateFormat="dd-MM-yyyy" locale="nl" selected={publishDate} onChange={(date) => updateObject({target:{name:"change-date", "dataset": {"index": index, "date":datasetKey}, value:date}})} />
                            </div>
                            <input type="time" value={post.pubTime ? post.pubTime : "07:00:00"} onChange={(e) => updatePostObject({target:{name:"pubTime", "dataset": {"index": index, "date":datasetKey}, value:e.target.value}})} />
                        </div>
                    </div>
                    <div key={6} className="list__needs">
                        <div className="list__input">
                            {outputPostInformation(post, index, baseDir, publishDate)}
                        </div>
                    </div>
                </div>);
            });
        });
        return (
            <div key={3} className="list__container">
                {list}
            </div>
        );
    }

    const addPostToDate = () => {
        if(!addDate){ return; }
        
        const key = addDate.getFullYear()+""+addDate.getMonth()+""+addDate.getDate();
        const newData = { ...data };
        if(!newData.items[key]){
            newData.items[key] = {
                posts : [],
                date: addDate
            }
        }

        const dateItem = {
            ...newData.items[key]
        };
        dateItem.posts.push({
            title:"",
            notes:"",
            description:"",
            image:{},
            feedback:[],
            input: []
        });

        newData.items[key] = dateItem;
        setData(newData);
        setAddDate(null);
    }

    const outputView = () => {
        if(!data){ return null; }
        if(!data.items){ return null; }
        return (
            <section key={0} className="edit__content__planning__container">
                <div key={0} className="container">
                    <h2 key={0}>{data.title}</h2>
                    {displaySidebar()}
                    {displayListView()}
                </div>
            </section>
        );
    } 
 
    if(!isAdmin()){
        return null;
    }
    return outputView();
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewContentPlanning);
