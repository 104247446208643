import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams, Route, Link } from "react-router-dom";

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nl from "date-fns/locale/nl";

import { contentInsert } from "../../classes/database/Content";

import { displayDate, days } from "../../helpers/functions";

import {
	setActiveUser,
	setAccountDetailPosts,
	setCurrentAccount,
	setCurrentLeads,
	setAccounts,
	setPosts,
	setCurrentPosts,
} from "../../redux";
import {
	getLeads,
	getPosts,
	getAccounts,
	getAccountLeads,
	getAccountPosts,
	getAccount,
} from "../../selectors";
import { useAuth } from "../../contexts/AuthContext";

registerLocale("nl", nl);


function NewContentPlanning(props) {
    const [data, setData] = useState({productionTime:3});
	const { isAdmin } = useAuth();

    const updateData = (e, index = null) => {
        const newData = {...data};
        if(e.target.name == "rubrics"){
            if(!newData[e.target.name]){ newData[e.target.name] = ['']; }
			if(index === null){
				newData[e.target.name].push(e.target.value);
			} else {
				newData[e.target.name][index] = e.target.value;
			}
        } else {
            newData[e.target.name] = e.target.value;
        }
        setData(newData);
    }

	const selectDay = (e, number) => {
		let key = "";
		if(number == 1){
			key = 'first';
		} else if(number == 2){
			key = 'second';
		}

		const newData = data.placement ? {...data.placement} : {};
		newData[key] = e.target.dataset.value;
		updateData({target: {
			name: "placement",
			value: newData
		}});
	}

	const removeRubric = (index) => {
		const newData = {...data};
		newData.rubrics = newData.rubrics.filter((item, key) => {
			return index == key ? false : true;
		});
		setData(newData);
	}

	const storePlanning = async () => {
		const zohoId = await createZohoRecord();
		if(!zohoId){
			return;
		}

		const periods =  buildPeriods();
		const newData = { ...data, 'zohoId' : zohoId, phases: periods }
		contentInsert(zohoId, newData, props.userId);
		props.finish(zohoId, newData, props.userId);
	}

	const buildPeriods = () => {
		const periods = [];
		let current = new Date(data.startPeriod);
		let end = new Date(data.endPeriod);

		let productionDays = 7 + 7 +7 + 14;
		
		/* Content creation */
		while(current < end){
			let adjusted = new Date(current);
			let deadline = new Date(adjusted);
			deadline.setDate(deadline.getDate() - 7);
			let feedback2 = new Date(deadline);
			feedback2.setDate(feedback2.getDate() - 7);
			let feedback1 = new Date(feedback2);
			feedback1.setDate(feedback1.getDate() - 7);
			let start = new Date(feedback1);
			start.setDate(start.getDate() - 14);

			periods.push({
				"Type": {type:"choice", data:"Content creatie"},
				"Start_periode" :  {type:"date", 'data':displayDate(start)},
				"Eind_periode" :  {type:"date", 'data':displayDate(adjusted)},
				"Deadline" :  {type:"date", 'data':displayDate(deadline)},
				"Feedback_datum_1" :  {type:"date", 'data':displayDate(feedback1)},
				"Feedback_datum_2" :  {type:"date", 'data':displayDate(feedback2)},
			});

			current.setMonth(current.getMonth() + parseInt(data.productionTime));
		}
		/* Collect input */
		current = new Date(data.startPeriod);
		while(current < end){
			let deadline = new Date(current);
			deadline.setDate(current.getDate() - productionDays);
			
			let feedback2 = new Date(deadline);
			feedback2.setDate(feedback2.getDate() - 7);
			
			let feedback1 = new Date(feedback2);
			feedback1.setDate(feedback1.getDate() - 7);

			let start = new Date(feedback1);
			start.setMonth(start.getMonth() - 1);

			periods.push({
				"Type": {type:"choice", data:"Input verzamelen"},
				"Start_periode" :  {type:"date", data:displayDate(start)},
				"Eind_periode" :  {type:"date", data:displayDate(deadline)},
				"Deadline" :  {type:"date", data:displayDate(deadline)},
				"Feedback_datum_1" :  {type:"date", 'data':displayDate(feedback1)},
				"Feedback_datum_2" :  {type:"date", 'data':displayDate(feedback2)},
			});

			current.setMonth(current.getMonth() + 6);
		}
		return periods;
	}

	const buildData = () => {
		const periods =  buildPeriods();
		const frequency = {
			"1":"Eens per week",
			"2":"Twee keer per week",
			"1.5":"Eens per week + uitgelichte post Eens per twee weken"
		};
		return {
			"Name" : data.title,
			"Deadlines" : periods,
			"Doelgroep" : data.targetgroup,
			"Startdatum_planning" :  {type:"date", 'data': displayDate(data.startPeriod)},
			"Einddatum_planning" :  {type:"date", 'data': displayDate(data.endPeriod)},
			"Frequentie" : {'data':frequency[data.frequency], 'type':'choice'},
			"Plaatsing_post_1" : {'data':data.placement.first != 'free' ? days[data.placement.first] : "Vrije selectie", 'type':'choice'},
			"Plaatsing_post_2" :{'data':data.placement.second != 'free' ? days[data.placement.second] : "Vrije selectie", 'type':'choice'} ,
			"Rubrieken" : data.rubrics.join(`
`)
		}
	}

	const createZohoRecord = async () => {
		const newData = buildData();
		const rawResponse = await fetch('https://socialrss.zodoende.nl/create/Contentplanningen/', {
			method: 'POST',
			mode: 'cors',
			headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
			},
			body: JSON.stringify(newData)
		});
		const response = await rawResponse.json();
		
		if(response.code == 201){
			return response.data[0];
		}
		return null;
	}
 
    if(!isAdmin()){
        return null;
    }
    return <section className="add__content__planning__container container">
        <article className="content__planning__form">
        <h1>Nieuwe Content Planning</h1>
			<div className="grid two">
				<div>
					<h2>Titel</h2>
            		<input key={0} onInput={updateData} value={data.title ? data.title : ""} type="text" name="title" placeholder="Naam invoeren"/>
				</div>
				<div>
					<h2>Doelgroep</h2>
					<input key={1} onInput={updateData} value={data.targetgroup ? data.targetgroup : ""} type="text" name="targetgroup" placeholder="Doelgroep invoeren"/>
				</div>
			</div>
			<h2>Voorproductie Content</h2>
			<div className="grid two">
				<input key={1} onInput={updateData} value={data.productionTime ? data.productionTime : 3} type="number" name="productionTime" placeholder="Productie voor aantal maanden"/>
				<span style={{margin: "auto 0"}}>in maanden</span>
			</div>
			<h2>Post Frequentie</h2>
			<div key={2} className="content__frequency">
                <label key={0}>
                    <input key={0} onChange={updateData} checked={data.frequency == '1' ? true : false} type="radio" name="frequency" value="1"/>
                    <span key={1}>Eens per week</span>
                </label>
                <label key={1}>
                    <input key={0} onChange={updateData} checked={data.frequency == '2' ? true : false} type="radio" name="frequency" value="2"/>
                    <span key={1}>Twee keer per week</span>
                </label>
                <label key={2}>
                    <input key={0} onChange={updateData} checked={data.frequency == '1.5' ? true : false} type="radio" name="frequency" value="1.5"/>
                    <span key={1}>Eens per week + uitgelichte post Eens per twee weken</span>
                </label>
            </div>
			<h2>Plaatsen</h2>
			<div className="post__placement">
				<p>Op welke dag moet de eerste post geplaatst worden.</p>
				<div className="first day__select">
					<div onClick={(e)=>selectDay(e, 1)} className={`daySelect${data.placement && data.placement.first == '1'? ' active' : ''}`} data-value='1'>ma</div>
					<div onClick={(e)=>selectDay(e, 1)} className={`daySelect${data.placement && data.placement.first == '2'? ' active' : ''}`} data-value='2'>di</div>
					<div onClick={(e)=>selectDay(e, 1)} className={`daySelect${data.placement && data.placement.first == '3'? ' active' : ''}`} data-value='3'>wo</div>
					<div onClick={(e)=>selectDay(e, 1)} className={`daySelect${data.placement && data.placement.first == '4'? ' active' : ''}`} data-value='4'>do</div>
					<div onClick={(e)=>selectDay(e, 1)} className={`daySelect${data.placement && data.placement.first == '5'? ' active' : ''}`} data-value='5'>vr</div>
					<div onClick={(e)=>selectDay(e, 1)} className={`daySelect${data.placement && data.placement.first == '6'? ' active' : ''}`} data-value='6'>za</div>
					<div onClick={(e)=>selectDay(e, 1)} className={`daySelect${data.placement && data.placement.first == '0'? ' active' : ''}`} data-value='0'>zo</div>
					<div onClick={(e)=>selectDay(e, 1)} className={`daySelect${data.placement && data.placement.first == 'free'? ' active' : ''}`} data-value='free'>Instellen per item</div>
				</div>
			</div>
			{(data.frequency == '1.5' || data.frequency == '2') && <div className="post__placement">
				<p>Op welke dag moet de tweede post geplaatst worden.</p>
				<div className="grid two second__week">
					<div className="second day__select">
						<div onClick={(e)=>selectDay(e, 2)} className={`daySelect${data.placement && data.placement.second == '1'? ' active' : ''}`} data-value='1'>ma</div>
						<div onClick={(e)=>selectDay(e, 2)} className={`daySelect${data.placement && data.placement.second == '2'? ' active' : ''}`} data-value='2'>di</div>
						<div onClick={(e)=>selectDay(e, 2)} className={`daySelect${data.placement && data.placement.second == '3'? ' active' : ''}`} data-value='3'>wo</div>
						<div onClick={(e)=>selectDay(e, 2)} className={`daySelect${data.placement && data.placement.second == '4'? ' active' : ''}`} data-value='4'>do</div>
						<div onClick={(e)=>selectDay(e, 2)} className={`daySelect${data.placement && data.placement.second == '5'? ' active' : ''}`} data-value='5'>vr</div>
						<div onClick={(e)=>selectDay(e, 2)} className={`daySelect${data.placement && data.placement.second == '6'? ' active' : ''}`} data-value='6'>za</div>
						<div onClick={(e)=>selectDay(e, 2)} className={`daySelect${data.placement && data.placement.second == '0'? ' active' : ''}`} data-value='0'>zo</div>
						<div onClick={(e)=>selectDay(e, 2)} className={`daySelect${data.placement && data.placement.second == 'free'? ' active' : ''}`} data-value='free'>Instellen per item</div>
					</div>
					{(data.frequency == '1.5' &&<div className="week__toggle">
						<div onClick={() => updateData({target:{name:"onceEveryOtherWeek",value:'even'}})} className={`even${data.onceEveryOtherWeek == 'even' ? ' active' : ''}`}>Even weken</div>
						<div onClick={() => updateData({target:{name:"onceEveryOtherWeek",value:'odd'}})}className={`odd${data.onceEveryOtherWeek == 'odd' ? ' active' : ''}`}>Oneven weken</div>
					</div>)}
				</div>
			</div>}
			<h2>Rubrieken</h2>
			<div key={5} className="planning__rubrics">
                {(data.rubrics || [""]).map((item, index)=>{
                    return <div key={index} className="rubric__container">
                        <input key={0} onInput={(e) => updateData(e, index)} type="text" value={item} name="rubrics" placeholder="Rubriek invoeren"/>
                        <button className="button remove__rubric" onClick={() => removeRubric(index)}>x</button>
                    </div>
                })}
                <button key={1} onClick={()=>updateData({target:{name:"rubrics",value:""}})} className="button">Rubriek toevoegen</button>
            </div>
        </article>
		<aside className="content__info__box">
			<p><strong>Klant</strong><span>{props.current.account.name}</span></p>
			<h2>Startdatum Planning</h2>
            <DatePicker placeholderText="Selecteer datum.." dateFormat="dd-MM-yyyy" locale="nl" selected={data.startPeriod} onChange={(date) => updateData({target:{name:"startPeriod", value:date}})} />
            <h2>Einddatum Planning</h2>
			<DatePicker placeholderText="Selecteer datum.." dateFormat="dd-MM-yyyy" locale="nl" selected={data.endPeriod} onChange={(date) => updateData({target:{name:"endPeriod", value:date}})} /> 
			<div>
				<button className="store__planning button" onClick={storePlanning}>Opslaan</button>
			</div>
		</aside>
    </section>;
}

const mapDispatchToProps = (dispatch) => {
	return {
		setActiveUser: (user) => {
			dispatch(setActiveUser(user));
		},
		setAccountDetailPosts: (posts) => {
			dispatch(setAccountDetailPosts(posts));
		},
		setCurrentAccount: (user) => {
			dispatch(setCurrentAccount(user));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
		setPosts: (posts) => {
			dispatch(setPosts(posts));
		},
		setCurrentPosts: (posts) => {
			dispatch(setCurrentPosts(posts));
		},
		setCurrentLeads: (posts) => {
			dispatch(setCurrentLeads(posts));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		collections: {
			leads: getLeads(state),
			posts: getPosts(state),
			accounts: getAccounts(state),
		},
		current: {
			leads: getAccountLeads(state),
			account: getAccount(state),
			posts: getAccountPosts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			mode: state.accountDetailReducer.mode,
		},
		parser: {
			user: state.parserReducer.user,
			type: state.parserReducer.type,
			isFocused: state.parserReducer.isFocused,
			isRunning: state.parserReducer.isRunning,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(NewContentPlanning);
