/*

	opslaanbaar / bewerkbaar maken

	PDF genereren op basis van selectie

*/
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation";

import { getUser, getAccounts } from "./selectors";
import { setLoggedInAccount, setAccounts } from "./redux";

import AccountView from "./components/accounts/view/AccountView";
import ContentMarketingView from "./components/accounts/view/ContentMarketingView";
import ContentPlanningView from "./components/planning/ContentPlanningView";
import ViewContentPlanning from "./components/planning/ViewContentPlanning";
import SocialSellingView from "./components/accounts/view/SocialSellingView";
import AccountCreate from "./components/accounts/create/AccountCreate";
import Accounts from "./components/Accounts";
import Companies from "./components/Companies";
import PrivateRoute from "./components/PrivateRoute";
import ProtectedRoute from "./components/ProtectedRoute";
import CustomerLeadView from "./components/leads/CustomerLeadView";
import LeadView from "./components/leads/LeadView";
import SocialSelling from "./components/SocialSelling";
import BooleanSearch from "./components/BooleanSearch";
import Messages from "./components/Messages";

import CustomerView from "./components/accounts/CustomerView"

import { AuthProvider } from "./contexts/AuthContext";
import UpdateProfile from "./components/user/UpdateProfile";
import SignUp from "./components/user/SignUp";
import Login from "./components/user/Login";
import ForgotPassword from "./components/user/ForgotPassword";
import PreviewContent from "./components/planning/PreviewContent";
import Onboarding from "./components/onboarding/Onboarding";

// clean up any session storage on start up with useEffect() hook

function App(props) {
	const setLoggedInUser = (account) => {
		const _accounts = { ...props.collection.accounts };
		_accounts[account.documentId] = account;

		props.setAccounts(_accounts);
		props.setLoggedInAccount(account);
	};

	const outputPage = () => {
		return [
			<Navigation key={0} />,
			<Routes key={1}>
				<Route path="/login" element={<Login />}></Route>
				<Route
					path="/wachtwoord-vergeten"
					element={<ForgotPassword />}></Route>
				<Route
					path="/berichten/:id"
					element={
						<PrivateRoute>
							<Messages />
						</PrivateRoute>
					}></Route>
				<Route
					path="/account/:id/onboarding"
					element={
						<PrivateRoute>
							<Onboarding />
						</PrivateRoute>
					}></Route>
				<Route
					path="/profiel"
					element={
						<PrivateRoute>
							<UpdateProfile />
						</PrivateRoute>
					}></Route>
				<Route
					path="/accounts"
					element={
						<PrivateRoute>
							<Accounts />
						</PrivateRoute>
					}></Route>
				<Route
					path="/account/:id"
					element={
						<PrivateRoute>
							<AccountView />
						</PrivateRoute>
					}></Route>
				<Route
					path="/account/:id/social-selling"
					element={
						<PrivateRoute>
							<SocialSellingView />
						</PrivateRoute>
					}></Route>
				<Route
					path="/account/:id/social-selling/posts"
					element={
						<PrivateRoute>
							<SocialSellingView mode="posts" />
						</PrivateRoute>
					}></Route>
				<Route
					path="/account/:id/social-selling/leads"
					element={
						<PrivateRoute>
							<SocialSellingView mode="leads" />
						</PrivateRoute>
					}></Route>

				<Route
					path="/account/:id/social-selling/lead/:lead"
					element={
						<PrivateRoute>
							<LeadView />
						</PrivateRoute>
					}></Route>
				<Route
					path="/account/:id/social-selling/parser"
					element={
						<PrivateRoute>
							<SocialSellingView mode="parser" />
						</PrivateRoute>
					}></Route>
					
				<Route
					path="/account/:id/content-marketing"
					element={
						<PrivateRoute>
							<ContentPlanningView />
						</PrivateRoute>
					}></Route>
				<Route
					path="/account/:id/content-marketing/:planningId"
					element={
						<PrivateRoute>
							<ViewContentPlanning />
						</PrivateRoute>
					}></Route>
				<Route
					path="/account/:id/content-marketing/:planningId/preview"
					element={
						<PrivateRoute>
							<PreviewContent />
						</PrivateRoute>
					}></Route>
				<Route
					path="/account/aanmaken"
					element={
						<PrivateRoute>
							<SignUp />
						</PrivateRoute>
					}></Route>
				<Route
					path="/bedrijven"
					element={
						<PrivateRoute>
							<Companies />
						</PrivateRoute>
					}></Route>
				<Route
					path="/social-selling"
					element={
						<PrivateRoute>
							<SocialSelling />
						</PrivateRoute>
					}></Route>
				<Route
					path="/social-selling/:id"
					element={
						<PrivateRoute>
							<SocialSelling />
						</PrivateRoute>
					}></Route>
				<Route
					path="/"
					element={
						<PrivateRoute>
							{
								<PrivateRoute>
									<Accounts />
								</PrivateRoute>
							}
						</PrivateRoute>
					}></Route>
				<Route
					path="/leads/:id/created/:created"
					element={
						<ProtectedRoute>
							<CustomerLeadView />
						</ProtectedRoute>
					}></Route>
				<Route
					path="/leads/:id/:hash"
					element={
						<ProtectedRoute>
							<CustomerLeadView />
						</ProtectedRoute>
					}></Route>
				<Route
					path="/leads/:id"
					element={
						<PrivateRoute>
							<CustomerLeadView />
						</PrivateRoute>
					}></Route>
				<Route
					path="/user/:id"
					element={
						<PrivateRoute>
							<CustomerView />
						</PrivateRoute>
					}></Route>

				<Route
					path="/boolean-search/:id"
					element={
						<PrivateRoute>
							<BooleanSearch />
						</PrivateRoute>
					}></Route>
			</Routes>,
		];
	};

	return (
		<BrowserRouter>
			<AuthProvider user={props.user} setLoggedInUser={setLoggedInUser}>
				{outputPage()}
			</AuthProvider>
		</BrowserRouter>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInAccount: (users) => {
			dispatch(setLoggedInAccount(users));
		},
		setAccounts: (users) => {
			dispatch(setAccounts(users));
		},
	};
};

const mapStateToProps = (state) => {
	return {
		user: getUser(state),
		collection: {
			accounts: getAccounts(state),
		},
		appState: state.appReducer.appState,
		detail: {
			parseType: state.parserReducer.type,
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
